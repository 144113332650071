<template>
  <div>
    <!--begin::Signin-->
    <div class="login-form login-signin">
      <div class="pb-5 pt-lg-0 pt-5">
        <h3 class="font-weight-bolder text-dark font-size-h3 font-size-h1-lg">Sign In</h3>
        <span class="text-muted font-weight-bold font-size-h4">Enter your username and password</span>
      </div>

      <b-alert :show="(errors ? true : false)" fade>{{ errors }}</b-alert>

      <!--begin::Form-->
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <b-form-group
          id="form-input-group-1"
          label-class="font-size-h6 font-weight-bolder text-dark pt-5"
          label="Username/Email"
          label-for="form-input-1"
        >
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            id="form-input-1"
            name="form-input-1"
            autocomplete="off"
            v-model="$v.form.email.$model"
            placeholder="Username/Email"
            :state="validateState('email')"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-1-live-feedback">
            Email/Username is required and minimal 3 length.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="form-input-group-2"
          label=""
          label-for="form-input-2"
        >
          <div class="d-flex justify-content-between mt-n5">
            <label class="font-size-h6 font-weight-bolder text-dark pt-5">Password</label>
            <router-link
              class="text-primary font-size-h6 font-weight-bolder text-hover-primary cursor-pointer pt-5" id="kt_login_forgot"
              :to="{ name: 'auth-forgot-password' }"
              tabindex="-1"
            >
              Forgot Password ?
            </router-link>
          </div>
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            type="password"
            id="form-input-2"
            name="form-input-2"
            v-model="$v.form.password.$model"
            placeholder="Your Password"
            :state="validateState('password')"
            aria-describedby="input-2-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-2-live-feedback">
            Password is required and minimal 3 length.
          </b-form-invalid-feedback>
        </b-form-group>

        <!--begin::Action-->
        <div
          class="form-group d-flex flex-wrap justify-content-between align-items-center"
        >
          <button
            ref="kt_login_signin_submit"
            class="btn btn-primary font-weight-bolder font-size-h6 px-9 py-4 mr-1 my-3 font-size-3"
          >
            Sign In
          </button>
          
          <button
            type="button"
            @click="redirectSSO"
            class="btn btn-light-primary font-weight-bolder font-size-h6 px-9 py-4 ml-1 my-3 font-size-3"
          >
            Login With SSO
          </button>
        </div>
        <!--end::Action-->
      </b-form>
      <!--end::Form-->
    </div>
    <!--end::Signin-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from "vuex";
import { LOGIN, KILL } from "@/core/services/store/auth.module";

import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      // Remove this dummy login info
      form: {
        email: null,
        password: null
      }
    };
  },
  validations: {
    form: {
      email: {
        required,
        minLength: minLength(3)
      },
      password: {
        required,
        minLength: minLength(3)
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    removeSpinner(button) { 
      button.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const email = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;

      // clear existing errors
      if (this.$store.getters.isAuthenticated) {
        this.$store.dispatch(KILL);
      }

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      // setTimeout(() => {
        // send login request
        this.$store
          .dispatch(LOGIN, { email, password })
          // go to which page after successfully login
          .then(() => {
            this.removeSpinner(submitButton)
            if (this.$route.query.rget && this.$route.query.rget != '/auth/login') {
              return this.$router.push({ path: this.$route.query.rget }).catch(()=>{}) 
            }
            this.$router.push({ 
                                name: "auth-select-company", 
                                query: {
                                  rget: this.$route.query.rget
                                } 
                              }).catch(()=>{})

          })
          .catch( err => {
            this.removeSpinner(submitButton)
          })

      // }, 2000);
    },
    redirectSSO() {
      window.location.href = `${process.env.VUE_APP_API_URL}/oauth/authorize?client_id=${process.env.VUE_APP_OAUTH2_CLIENT_ID}&redirect_uri=${encodeURIComponent(process.env.VUE_APP_OAUTH2_REDIRECT_URI)}&response_type=code`
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  },
  mounted() {
    if (this.$route.query.aulo && this.$route.query.aulo != '') {
      if (this.$route.query.rget && this.$route.query.rget != '/auth/login') {
        window.localStorage.setItem("rget_as_next", this.$route.query.rget);
      }
      this.redirectSSO()
    }
  },
};
</script>
